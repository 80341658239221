import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { httpOrigin } from '~configs/apiOrigin';
import multiCurrencyDefaultSettings from '~configs/multiCurrencyDefaultSettings';
import tradingWidgetDefaultSettings from '~configs/tradingWidgetDefaultSettings';
import { resetTimeDefault } from '~configs/dateFormatStr';
import { reduxRemoveSettings } from '~actions/ui';
import checkPlatforms, { apiVersion } from './platform';
import {
  layoutDomains,
  widgetsWithBoxesSettings,
  widgetsWithSubWidgetsSettings,
  widgetsWithWidgetsSettings,
} from '../constants';

export const isValidJSONString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    console.log(`With inValid JSON ***   ${str}   ***    error => ${e} `);
    return false;
  }
  return JSON.parse(str);
};

export function restoreSettings() {
  localStorage.clear();
  const apiUrlOrigin = httpOrigin(
    `/trader-rest-service/${apiVersion()}/user-settings/all`
  );

  fetch(apiUrlOrigin, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `${sessionStorage.getItem('token')}`,
    },
  })
    .then(() => {
      // sucessfull, do nothingmultiCurrencyDefaultSettings
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function resetSetting(key, domain) {
  if (layoutDomains.includes(domain))
    localStorage.removeItem(
      `_${sessionStorage.getItem('global_username')}_${domain}`
    ); // This is the only resettable setting atm

  const apiUrlOrigin = httpOrigin(
    `/trader-rest-service/${apiVersion()}/user-settings`
  );
  fetch(apiUrlOrigin, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `${sessionStorage.getItem('token')}`,
    },
    body: JSON.stringify({
      key,
      domain,
    }),
  })
    .then(() => {
      // sucessfull, do nothing
    })
    .catch((err) => {
      console.warn(err);
    });
}

export function sendSetting(key, value, domain) {
  if (window?.socket?.readyState !== 1) return;

  window.socket.send(
    JSON.stringify({
      body: {
        msg_type: 'save-user-settings',
        data: {
          key,
          value,
          domain,
          token: `${sessionStorage.getItem('token')}`,
        },
      },
    })
  );
}

export function explodeSetting(path, value) {
  const keys = path.split('.');
  const last = keys.pop();
  const newObj = {};
  keys.reduce((o, k) => o[k] || {}, newObj)[last] = value;
  return newObj;
}

export const dispatchRemoveSettings = (
  dispatch,
  widgetId,
  widgetDb,
  delayTime
) => {
  setTimeout(
    () => dispatch(reduxRemoveSettings(widgetId, widgetDb)),
    delayTime
  );
};

export const removeWidgetSettings = (domain, key, newIdentifier, settings) => {
  const cleanSetting = omit(settings, newIdentifier);

  // if identifier is found, then widget has been customized
  if (!isEqual(Object.keys(cleanSetting), Object.keys(settings))) {
    sendSetting(key, cleanSetting, domain);
  }

  return cleanSetting;
};

// identifier
// Old  (znl1LnHQazEj) vs  new  (#1e4db999-fa78-4604-9d7f-da663aa7b25c)
const findGoldenLayoutIdentifier = (settingsWithIdentifier) => {
  const identifierKeys = Object.keys(settingsWithIdentifier);
  const removeIdentifierKeys = identifierKeys.reduce(
    (accumulator, currentValue) => {
      if (!currentValue.startsWith('#') && !currentValue.match(/^[0-7]{1}$/)) {
        accumulator.push(currentValue);
      }
      return accumulator;
    },
    []
  );

  return removeIdentifierKeys;
};

export const removeSettingsForGoldenLayout = (domain, settings) => {
  if (widgetsWithBoxesSettings.includes(domain)) {
    const boxes = { ...settings.boxes };

    // system default values,
    if (Array.isArray(boxes)) {
      return settings;
    }

    // not system default values, need to check
    const removeBoxKeys = findGoldenLayoutIdentifier(boxes);

    // has goldenLayout user settings, need to clean it
    if (removeBoxKeys.length) {
      return { ...settings, boxes: omit(boxes, removeBoxKeys) };
    }

    return settings;
  }

  if (widgetsWithSubWidgetsSettings.includes(domain)) {
    const subWidgets = { ...settings.sub_widgets };

    // system default values,
    if (isEmpty(subWidgets)) {
      return settings;
    }

    const removeWidgetKeys = findGoldenLayoutIdentifier(subWidgets);

    // has goldenLayout user settings, need to clean it
    if (removeWidgetKeys.length) {
      return {
        ...settings,
        sub_widgets: omit(subWidgets, removeWidgetKeys),
      };
    }

    return settings;
  }

  if (widgetsWithWidgetsSettings.includes(domain)) {
    const widgets = { ...settings.widgets };

    // system default values,
    if (isEmpty(widgets)) {
      return settings;
    }

    const removeWidgetKeys = findGoldenLayoutIdentifier(widgets);

    // has goldenLayout user settings, need to clean it
    if (removeWidgetKeys.length) {
      return {
        ...settings,
        widgets: omit(widgets, removeWidgetKeys),
      };
    }

    return settings;
  }

  // others
  return settings;
};

export const localStorageAsDefaultUserSettings = () => {
  const localSettings = {};

  // Make this able to read old properties!
  // map only current user settings to redux store after login
  if (sessionStorage.getItem('token')) {
    const currentUserName = sessionStorage.getItem('global_username');
    const keyStartAt = `_${currentUserName}_`;
    const settingsFromLS = Object.entries(localStorage);

    for (let i = 0; i < settingsFromLS.length; i += 1) {
      const [key, settings] = settingsFromLS[i];

      if (key.indexOf(keyStartAt) === 0) {
        const [, domain] = key.split(keyStartAt);
        const validSettings = isValidJSONString(settings);

        if (validSettings) {
          if (domain === 'global_settings') {
            // This are root on redux, that's why we do this
            const globalSettings = Object.entries(validSettings);
            for (let j = 0; j < globalSettings.length; j += 1) {
              const [gKey, gSettings] = globalSettings[j];
              localSettings[gKey] = gSettings;
            }
          } else if (
            domain === (domain.match(/\w+-\w+\.+.+/) || {}).input ||
            ['instrument', 'golden_layout'].includes(domain)
          ) {
            // remove following domains from local storage, they are not used
            // any domain with symbole ( slippage and trading widget)
            // instrument, golden_layout
            localStorage.removeItem(key);
          } else {
            // clean golden layout user settings when loading from LS
            const cleanSettings = removeSettingsForGoldenLayout(
              domain,
              validSettings
            );
            localSettings[domain] = cleanSettings;

            if (!isEqual(validSettings, cleanSettings)) {
              localStorage.setItem(`${keyStartAt}${domain}`, cleanSettings);
            }
          }
        } else {
          localStorage.removeItem(key);
        }
      }
    }
  }

  return localSettings;
};

export const getDefaultSettings = () => ({
  clocks: true,
  expire_time: 5,
  initial_order_notification: false,
  lang: window.APP_NAME === 'edgefx' ? 'en' : 'es',
  sound: false,
  sound_viewer: false,
  notify_fixed: false,
  stop_order_opposite: false,
  inverse_rate_notification: false,
  trading_widget: tradingWidgetDefaultSettings,
  ladder_widget: { widgets: {} },
  multicurrency_widget: {
    globalAmountArray: [
      { value: 500000, abr: '500K' },
      { value: 1000000, abr: '1M' },
      { value: 2000000, abr: '2M' },
      { value: 3000000, abr: '3M' },
    ],
    ...(multiCurrencyDefaultSettings[window.APP_NAME] ||
      multiCurrencyDefaultSettings.default),
    boxes: [
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'sp',
        orderType: 'LIMIT',
        tif: 'IOC',
        amount: '1,000,000',
        isLadderFrac: false,
      },
    ],
  },
  rfs_widget: {
    globalAmountArray: [
      { value: 500000, abr: '500K' },
      { value: 1000000, abr: '1M' },
      { value: 2000000, abr: '2M' },
      { value: 3000000, abr: '3M' },
    ],
    double_click: true,
    boxes: [
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
      {
        instrument: 'UNSELECTED',
        instrumentId: 0,
        tenor: 'rfq',
        orderType: 'LIMIT',
        tif: 'FOK',
        rfsDate: '',
        rfsDateFar: '',
        rfsTenor: '',
        rfsTenorFar: '',
        amount: '1,000,000',
        isLadderFrac: false,
        rfsType: 'outright',
        uuid: '',
      },
    ],
  },
  pnl_widget: {
    group_by_pair: false,
    show_term_amount: false,
    reset_time: resetTimeDefault,
  },
  rfq_widget: {},
  rfq_blotter_widget: {},
  blotter_widget: {
    blotter_tab: checkPlatforms(['edgefx']) ? 'todayOrders' : 'todayTrades',
  },
  instruments_widget: {
    order: [],
  },
  client_pricing_widget: {
    sub_widgets: {},
  },
  slippage_widget: {
    LIMIT: 0.2,
    MARKET: 30,
    instruments: {},
  },
});
